<template>
    <div ref="clock"  id="clock" :style="{ '--background': config.colors.primary, '--color': color() }">
        <OptionsComponent/>
        <ImagesComponent :players="players" :use-player-images="config.playerImages" :image-size="config.playerImageSize"/>

        <div ref="container" class="container">
            <PlayersComponent/>
            <ClockComponent/>
            <VotesComponent/>
        </div>

        <MessagesComponent/>
    </div>
</template>

<script>
import {io} from 'socket.io-client'
import ClockComponent from "@/components/ClockComponent";
import PlayersComponent from "@/components/PlayersComponent";
import VotesComponent from "@/components/VotesComponent";
import ImagesComponent from "@/components/ImagesComponent";
import MessagesComponent from "@/components/MessagesComponent";
import OptionsComponent from "@/components/OptionsComponent";

export default {
    name: 'ClockModule',

    components: {
        OptionsComponent,
        MessagesComponent,
        ImagesComponent,
        VotesComponent,
        PlayersComponent,
        ClockComponent
    },

    data() {
        return {
            config: {
                username: '',
                colors: {
                    primary: process.env.VUE_APP_COLOR_PRIMARY,
                    secondary: process.env.VUE_APP_COLOR_SECONDARY,
                    tertiary: process.env.VUE_APP_COLOR_TERTIARY
                },
                playerImages: true,
                playerImageSize: 64
            },
            rainbow: {
                selected: 0,
                interval: null,
                colors: ["#e87d7d", "#e88a7d", "#e8977d", "#e8a47d", "#e8b07d", "#e8bd7d", "#e8ca7d", "#e8d77d", "#e8e47d", "#dfe87d", "#d3e87d", "#c6e87d", "#b9e87d", "#ace87d", "#9fe87d", "#92e87d", "#86e87d", "#7de881", "#7de88e", "#7de89b", "#7de8a8", "#7de8b5", "#7de8c1", "#7de8ce", "#7de8db", "#7de8e8", "#7ddbe8", "#7dcee8", "#7dc1e8", "#7db5e8", "#7da8e8", "#7d9be8", "#7d8ee8", "#7d81e8", "#867de8", "#927de8", "#9f7de8", "#ac7de8", "#b97de8", "#c67de8", "#d37de8", "#df7de8", "#e87de4", "#e87dd7", "#e87dca", "#e87dbd", "#e87db0", "#e87da4", "#e87d97", "#e87d8a", "#e87d7d"]
            },
            voteNow: {},
            players: [],
            dateSeparatorState: true,
        }
    },

    computed: {
        title() {
            return document.title
        },
    },

    watch: {
        'config.username'(value) {
            document.title = (value !== '' ? value + ' · ' : '') + this.title
        },
        'config.colors.rainbow'(value) {
            if (value) {
                this.rainbow.interval = setInterval(() => {
                    if (this.rainbow.selected >= this.rainbow.colors.length - 1) this.rainbow.selected = 0
                    else this.rainbow.selected = this.rainbow.selected + 1
                }, 50)
            } else {
                clearInterval(this.rainbow.interval)
            }
        },
    },

    methods: {
        color() {
            return Object.keys(this.voteNow).length === 0 ? this.config.colors.secondary : this.config.colors.rainbow ? this.rainbow.colors[this.rainbow.selected] : this.config.colors.tertiary
        },
        updateDateSeparatorState() {
            this.dateSeparatorState = !this.dateSeparatorState
        },
        socketConnect() {
            this.players = []

            this.socket
                .on('err', (message) => console.error(message))
                .on('serverPlayers:bungee', (players) => {
                    this.players = players
                })
                .emit('serverPlayers', 'bungee')
        },
        updatePlayers(players) {
            this.players = players
        },
        play() {
            let audio = new Audio(this.config.sound.file)
            audio.volume = this.config.sound.volume
            audio.play()
        }
    },

    created() {
        setInterval(this.updateDateSeparatorState, 500)

        this.socket = io(process.env.VUE_APP_FULUTY_SOCKET)
        this.socket.on('connect', this.socketConnect)
        this.socket.on('reconnect', this.socketConnect)
    }
}
</script>