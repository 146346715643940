<template>
    <div class="timers-container">
        <VoteComponent v-for="vote in voteFilter" :key="vote.name" :vote="vote"/>
    </div>
</template>

<script>
import VoteComponent from "@/components/votes/VoteComponent";

export default {
    name: "VotesComponent",

    components: {VoteComponent},

    data() {
        return {
            interval: null,
            votes: {}
        }
    },

    computed: {
        username() {
            return this.$parent.config.username
        },
        voteFilter() {
            if (Object.keys(this.votes).length) {
                let list = [...this.votes]
                return list.splice(0, 2)
            }
            return []
        }
    },

    watch: {
        username() {
            this.requestVotes()
        }
    },

    methods: {
        requestVotes() {
            if (this.username !== '') {
                fetch(process.env.VUE_APP_FULUTY_API.replaceAll("%username%", this.$parent.config.username))
                    .then(response => response.json())
                    .then(response => {
                        this.votes = response.list

                        if (Object.keys(response.now).length > 0) {
                            if (this.$parent.config.sound.enable) {
                                this.$parent.play()
                            }
                            this.$parent.voteNow = response.now
                            this.startInterval()
                        } else {
                            this.$parent.voteNow = {}
                            this.stopInterval()
                        }

                    })
                    .catch(error => {
                        this.votes = {}
                        console.error(error)
                    });
            } else {
                this.votes = {}
                this.$parent.voteNow = {}
                this.stopInterval()
            }
        },
        startInterval() {
            if (this.interval == null) {
                this.interval = setInterval(this.requestVotes, 30000)
            }
        },
        stopInterval: function () {
            if (this.interval != null) {
                clearInterval(this.interval)
                this.interval = null
            }
        }
    }
}
</script>