<template>
    <div @click="requestFullscreen" class="clock-container">
        <div class="clock-sub">88:88:88</div>
        <div class="clock" v-text="date"></div>
    </div>
</template>

<script>
export default {
    name: 'ClockComponent',

    computed: {
        date() {
            let time = new Date().toLocaleTimeString()
            let sate = this.$parent.dateSeparatorState
            if (this.$parent.config.dateSeparator && !sate) {
                time = time.split(":").join(" ")
            }
            return time
        }
    },

    methods: {
        fullScreenStatus() {
            return !!this.$parent.$el.fullscreenElement;
        },
        requestFullscreen() {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
                this.$el.classList.add('zoom');
            } else {
                document.exitFullscreen()
                this.$el.classList.remove('zoom');
            }
        }
    }
}
</script>