<template>
    <div class="options-bar-option">
        <label :for="name">{{ label }}</label>
        <input v-model="value" :max="max" :min="min" :step="step" type="range">
    </div>
</template>

<script>
export default {
    name: 'RangeInput',

    props: {
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        },
        step: {
            type: Number,
            default: 1
        },
        default: {
            type: Number,
            default: 50
        }
    },

    data() {
        return {value: this.default}
    },

    mounted() {
        if (localStorage[this.name]) this.value = localStorage[this.name] !== undefined ? localStorage[this.name] : this.default;
        this.$parent.setConfig(this.name, this.value)
    },

    watch: {
        value(value) {
            localStorage[this.name] = value;
            this.$parent.setConfig(this.name, value)
        }
    }
}
</script>