<template>
    <div class="options-bar-option">
        <label :for="name">{{ label }}</label>
        <div class="options-bar-option-play">
            <select :id="name" v-model="value">
                <option v-for="item in items" :key="item.value" :value="item.value">{{ item.text }}</option>
            </select>
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" @click="play">
                <path d="M4 4l12 6-12 6z"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SelectMusicInput',

    props: {
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        default: {
            type: String,
            required: true
        }
    },

    data() {
        return {value: this.default}
    },

    methods: {
        play: function () {
            let audio = new Audio(this.value)
            audio.volume = this.$parent.sound.volume
            audio.play()
        }
    },

    mounted() {
        if (localStorage[this.name]) this.value = localStorage[this.name] !== undefined ? localStorage[this.name] : this.default;
        this.$parent.setConfig(this.name, this.value)
    },

    watch: {
        value(value) {
            localStorage[this.name] = value;
            this.$parent.setConfig(this.name, value)
        }
    }
}
</script>