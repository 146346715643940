<template>
    <div v-if="usePlayerImages" class="images-container">
        <div v-if="loaded">
            <ImageComponent v-for="player in players" :key="player.name" :player="player" :imageSize="imageSize" :style="imageRandomStyle()"/>
        </div>
    </div>
</template>

<script>
import ImageComponent from "@/components/images/ImageComponent";
import {debounce} from 'vue-debounce'

export default {
    name: 'ImagesComponent',

    components: {ImageComponent},

    props: {
        players: {
            type: Array,
            required: true
        },
        usePlayerImages: {
            type: Boolean,
            required: true
        },
        imageSize: {
            type: Number,
            required: false,
            default: 64
        }
    },

    data() {
        return {
            loaded: false
        }
    },

    computed: {
        resizeDebounce() {
            return debounce(this.resize, 250)
        }
    },

    watch: {
        'imageSize': function () {
            this.resize()
        },
        'players': function () {
            Object.keys(this.slots).forEach( slot => this.slots[slot] = false )
        }
    },

    methods: {
        imageRandomStyle() {
            const unusedKeys = Object.keys(this.slots).filter(key => this.slots[key] === false)
            if ( unusedKeys.length === 0 ) return { display: 'none' }
            const randomKey = unusedKeys[Math.floor(Math.random() * unusedKeys.length)]
            this.slots[randomKey] = true

            const [row, col] = randomKey.split('_')
            return {
                top: `${row * this.imageSize}px`,
                left: `${col * this.imageSize}px`,
                width: `${this.imageSize}px`,
                height: `${this.imageSize}px`,
                display: 'inline-block'
            }
        },
        resize() {
            this.loaded = false

            const clock = this.$parent.$refs.clock
            const cols = Math.floor(clock.clientWidth / this.imageSize)
            const rows = Math.floor(clock.clientHeight / this.imageSize)
            const padding = {
                x: (clock.clientWidth - cols * this.imageSize) / 2,
                y: (clock.clientHeight - rows * this.imageSize) / 2
            }

            this.$el.style.padding = padding.y + "px " + padding.x + "px "
            this.slots = {}

            const containerRect = this.$parent.$refs.container.getBoundingClientRect()
        
            for (let row = 0; row < rows; row++) {
                for (let col = 0; col < cols; col++) {
                    const image = { x: this.imageSize * col + padding.x, y: this.imageSize * row + padding.y,
                        width: this.imageSize, height: this.imageSize
                    }

                    if (image.x + image.width > containerRect.x && image.x < containerRect.x + containerRect.width) {
                        if (image.y + image.height > containerRect.y && image.y < containerRect.y + containerRect.height) {
                            continue
                        }
                    }

                    this.slots[`${row}_${col}`] = false
                }
            }

            this.loaded = true
        }
    },

    created() {
        this.$nextTick(function () {
            this.resize()
            window.addEventListener('resize', this.resizeDebounce)
        })
    },

    unmounted() {
        window.removeEventListener("resize", this.resizeDebounce)
    }
}
</script>