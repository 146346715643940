<template>
    <div class="players-container">
        <span class="player-sub">{{ String(8).padStart(String(playersCount()).length + 1, "8") }} 8888888</span>
        <span class="player" v-text="playerToString()"></span>
    </div>
</template>

<script>
export default {
    name: "PlayersComponent",

    methods: {
        playersCount() {
            return parseInt(this.$parent.players.length || 0)
        },
        playerToString() {
            return String(this.playersCount()).padStart(String(this.playersCount()).length + 1, ' ') + ' Joueur' + (this.playersCount() > 1 ? 's' : ' ')
        },
    }
}
</script>