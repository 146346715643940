<template>
    <div class="options-bar-option">
        <label :for="name">{{ label }}</label>
        <input :id="name" v-model.lazy="value" type="text"/>
    </div>
</template>

<script>
export default {
    name: 'TextInput',

    props: {
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        default: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            value: this.default,
        }
    },

    mounted() {
        if (localStorage[this.name]) this.value = localStorage[this.name] !== undefined ? localStorage[this.name] : this.default;
        this.$parent.setConfig(this.name, this.value)
    },

    watch: {
        value(value) {
            localStorage[this.name] = value;
            this.$parent.setConfig(this.name, value)
        }
    }
}
</script>