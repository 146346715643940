<template>
    <div :title="vote.site.name" class="timer-item">
        <span class="timer-sub">88:88:88</span>
        <span class="timer" v-text="timer"></span>
    </div>
</template>

<script>
export default {
    name: "VoteComponent",

    props: {
        vote: {
            type: Object,
            require: true
        }
    },

    data() {
        return {
            notified: false
        }
    },

    watch: {
        'vote.time'(newValue, oldValue) {
            if (newValue !== oldValue) this.notified = false
        }
    },

    computed: {
        next() {
            return new Date(this.vote.time)
        },
        timer() {
            let distance = this.distance()

            let time = '00:00:00'
            if (distance.getTime() > 0) {
                time = this.toTimeString(distance)
            } else if (!this.notified) {
                this.setNotified(true)
                this.$parent.requestVotes()
            }

            let sate = this.$parent.$parent.dateSeparatorState
            if (this.$parent.$parent.config.dateSeparator && !sate) {
                time = time.split(":").join(" ")
            }

            return time
        }
    },

    methods: {
        distance() {
            return new Date(this.next.getTime() - new Date().getTime())
        },
        setNotified(notified) {
            this.notified = notified
        },
        toTimeString(timestamp) {
            let hours = timestamp.getUTCHours();
            let minutes = timestamp.getUTCMinutes();
            let seconds = timestamp.getUTCSeconds();

            return hours.toString().padStart(2, '0') + ':' +
                minutes.toString().padStart(2, '0') + ':' +
                seconds.toString().padStart(2, '0');
        }
    },

}
</script>