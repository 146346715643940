<template>
    <div class="options-bar-option">
        <label :for="name">{{ label }}</label>
        <div class="options-bar-option-increment">
            <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" @click="decrement">
                <path d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
            </svg>
            <input type="text" :value="value + 'px'" disabled>
            <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" @click="increment">
                <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"/>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IncrementInput',

    props: {
        label: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        default: {
            type: Number,
            default: 64
        }
    },

    data() {
        return {value: this.default}
    },

    methods: {
        increment: function () {
            this.value = this.value * 2
            if ( this.value > 256) this.value = 256
        },
        decrement: function () {
            this.value = this.value / 2
            if ( this.value < 32) this.value = 32
        }
    },

    mounted() {
        if (localStorage[this.name]) this.value = localStorage[this.name] !== undefined ? Number.parseInt(localStorage[this.name]) : this.default;
        this.$parent.setConfig(this.name, this.value)
    },

    watch: {
        value(value) {
            localStorage[this.name] = value;
            this.$parent.setConfig(this.name, value)
        }
    }
}
</script>