<template>
    <div v-if="Object.keys($parent.voteNow).length > 0" class="messages-container">
        <div class="messages-sub"></div>

        <div class="messages wrap">
            <div class="content">
                <div v-for="n in 2" class="text" style="animation-duration: 15s;" :key="n">
                    <div v-for="(vote, key) in $parent.voteNow" :key="key" class="message-item">
                        <a :href="vote.site.link" target="_blank"><img :alt="vote.site.name" :src="vote.site.image">Tu
                            peux voter sur <span v-text="vote.site.name"></span>.</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "MessagesComponent",
}
</script>