<template>
    <div class="options">
        <svg v-show="!show" class="options-button" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"
             @click="show = ! show">
            <path d="M3.94 6.5L2.22 3.64l1.42-1.42L6.5 3.94c.52-.3 1.1-.54 1.7-.7L9 0h2l.8 3.24c.6.16 1.18.4 1.7.7l2.86-1.72 1.42 1.42-1.72 2.86c.3.52.54 1.1.7 1.7L20 9v2l-3.24.8c-.16.6-.4 1.18-.7 1.7l1.72 2.86-1.42 1.42-2.86-1.72c-.52.3-1.1.54-1.7.7L11 20H9l-.8-3.24c-.6-.16-1.18-.4-1.7-.7l-2.86 1.72-1.42-1.42 1.72-2.86c-.3-.52-.54-1.1-.7-1.7L0 11V9l3.24-.8c.16-.6.4-1.18.7-1.7zM10 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
        </svg>

        <div v-show="show || !interacted && sound.enable" class="options-wrapper" @click="show = false"></div>

        <div :class="{ show: show }" class="options-bar">
            <TextInput key="username" label="Pseudo" name="username"/>
            <SwitchInput key="dateSeparator" :default="false" label="Séparateur d'horloge" name="dateSeparator"/>
            <ColorInput key="colorsPrimary" :default="colors.primary" label="Couleur de fond" name="colors.primary"/>
            <ColorInput key="colorsSecondary" :default="colors.secondary" label="Couleur principale" name="colors.secondary"/>
            <SwitchInput label="Couleur d'alerte rainbow" :default="false" name="colors.rainbow" key="colorsRainbow"/>
            <ColorInput v-if="!colors.rainbow" key="colorsTertiary" :default="colors.tertiary" label="Couleur d'alerte" name="colors.tertiary"/>
            <SwitchInput key="playerImages" label="Images des joueurs" name="playerImages"/>
            <IncrementInput v-if="$parent.config.playerImages" key="playerImageSize" :default=64 label="Taille des images" name="playerImageSize"/>
            <SwitchInput key="soundEnable" :default="false" label="Effet sonore" name="sound.enable"/>
            <SelectMusicInput v-if="sound.enable" key="soundFile" :default="sound.list[0].value" :items="sound.list" label="Musique" name="sound.file"/>
            <RangeInput v-if="sound.enable" key="soundVolume" :default=1 :label="'Volume ' + Math.trunc(sound.volume * 100) + '%'" :max="1" :min="0.05" :step="0.05" name="sound.volume"/>
        </div>

        <div v-show="!interacted && sound.enable" class="options-modal">
            <span>La restriction du navigateur nécessite une activité sur la page pour permettre la lecture des sons</span>
            <button @click="interacted = true">Comprit</button>
        </div>
    </div>
</template>

<script>
import TextInput from "@/components/inputs/TextInput";
import SwitchInput from "@/components/inputs/SwitchInput";
import ColorInput from "@/components/inputs/ColorInput";
import SelectMusicInput from "@/components/inputs/SelectMusicInput";
import RangeInput from "@/components/inputs/RangeInput";
import IncrementInput from "@/components/inputs/IncrementInput";

import zelda from "@/assets/media/zelda.mp3";
import ender from "@/assets/media/ender_dragon_death.mp3";
import msn from "@/assets/media/msn.mp3";

export default {
    name: "OptionsComponent",

    components: {IncrementInput, RangeInput, SelectMusicInput, ColorInput, SwitchInput, TextInput},

    data() {
        return {
            show: false,
            interacted: false,
            colors: {
                primary: process.env.VUE_APP_COLOR_PRIMARY,
                secondary: process.env.VUE_APP_COLOR_SECONDARY,
                tertiary: process.env.VUE_APP_COLOR_TERTIARY,
                rainbow: false
            },
            sound: {
                enable: false,
                volume: 1,
                list: [
                    {text: 'Zelda', value: zelda},
                    {text: 'Ender Dragon', value: ender},
                    {text: 'MSN Messenger', value: msn},
                ],
            }
        }
    },

    methods: {
        setConfig(key, value) {
            if (key === "sound.enable") this.sound.enable = value
            else if (key === "sound.volume") this.sound.volume = value
            else if (key === "colors.rainbow") this.colors.rainbow = value
            this.setObjByString(this.$parent.config, key, value)
        },
        setObjByString(obj, str, val) {
            let keys, key
            if (!str || !str.length || Object.prototype.toString.call(str) !== "[object String]") return false
            if (obj !== Object(obj)) obj = {}
            keys = str.split(".")
            while (keys.length > 1) {
                key = keys.shift()
                if (obj !== Object(obj)) obj = {}
                if (!(key in obj)) obj[key] = {}
                obj = obj[key]
            }
            return obj[keys[0]] = val
        }
    }
}
</script>