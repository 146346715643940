<template>
    <img :alt="player.name" :src="'//skin.fuluty.fr/face/' + imageSize + '/' + player.name" :title="player.name"/>
</template>

<script>
export default {
    name: "ImageComponent",

    props: {
        player: {
            type: Object,
            required: true
        },
        imageSize: {
            type: Number,
            required: false,
            default: 64
        }
    }
}
</script>