import {createApp, h} from 'vue'
import Clock from './Clock.vue'

import 'reset-css';
import './assets/scss/app.scss'

createApp({
    render: () => h(Clock),
    module: {
        rule: [{
            test: /\.scss$/,
            use: [
                'vue-style-loader',
                'css-loader',
                'sass-loader'
            ]
        }]
    }
}).mount('[data-clock]')
